<template>
  <b-card
    class="purchasestockupitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="purchasestockupitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备货计划Id"
            label-for="ps_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="ps_id"
              size="sm"
              v-model="purchasestockupitem.ps_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备货计划编号"
            label-for="ps_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="ps_no"
              size="sm"
              v-model="purchasestockupitem.ps_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单明细Id purchase_order_item.item_id"
            label-for="order_item_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_item_id"
              size="sm"
              v-model="purchasestockupitem.order_item_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID 流货/品牌 共用"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="purchasestockupitem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称 流货/品牌 共用"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="purchasestockupitem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="申请采购数量 purchase_order_item.qty 流货/品牌 共用"
            label-for="qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="qty"
              size="sm"
              v-model="purchasestockupitem.qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="区域 品牌 专用"
            label-for="areas"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="areas"
              size="sm"
              v-model="purchasestockupitem.areas"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="金额 品牌 专用 purchase_order_item.product_cost_tax_subtotal"
            label-for="subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="subtotal"
              size="sm"
              v-model="purchasestockupitem.subtotal"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchasestockupitemStore from './purchasestockupitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      purchasestockupitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchasestockupitem')) store.registerModule('purchasestockupitem', purchasestockupitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchasestockupitem')) store.unregisterModule('purchasestockupitem')
    })

    const edit = function() {
      store.dispatch('purchasestockupitem/edit', {id: this.id}).then(res => {
        this.purchasestockupitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('purchasestockupitem/view', {id: this.id}).then(res => {
        this.purchasestockupitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('purchasestockupitem/save', this.purchasestockupitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>